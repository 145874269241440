import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Upload, Input, Button, Spin, Tabs } from "antd";
import {
  PlusOutlined
} from "@ant-design/icons";
import { Switch } from "antd";
import "moment-timezone";

import API, { SYS_LAYOUT, GAME } from "../../network/api";
import ModalCommon from "../../components/Modal";
import { TEXT_DEF } from "../../constant/text";
import { RIGHTS } from "../../constant/rights";
import {hasRight} from "../../components/until/right";
import { classicTitle } from "../../constant/lotteryTitle";
const { TextArea } = Input;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const getBase64Logo = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    alert('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    alert('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

const LayoutConfig = () => {
  let isRequest = useRef(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [keyUp, setKeyUp] = useState();
  const [dataDefault, setDataDefault] = useState();
  const [banners, setBanners] = useState([]);
  const [logo, setLogo] = useState();
  const [logoView, setLogoView] = useState();
  const [games, setGames] = useState([]);
  const [gamesXSTT, setGamesXSTT] = useState([]);
  const [gamesUp, setGamesUp] = useState([]);
  const [film, setFilm] = useState();
  const [livetreams, setLivestreams] = useState([]);
  const [livestreamShow, setLivestreamShow] = useState(1);
  const [watchFilmShow, setWatchFilmShow] = useState(1);
  const [liveUp, setLiveUp] = useState([]);
  const [uiFooter, setUiFooter] = useState();
  const [logoFtView, setLogoFtView] = useState();
  const [bannerLottery, setBannerLottery] = useState([]);
  const [bannerLotteryXSTT, setBannerLotteryXSTT] = useState([]);
  const [bannerOriginals, setBannerOriginals] = useState([]);

  const [indexTab, setIndexTab] = useState(0);
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isChangeGames, setIsChangeGame] = useState(false);

  // const [key, setKey] = useState();
  // const [idField, setIdField] = useState();

  useEffect(() => {
    getdataSystem();
    getDataGame();
    getGamesXSTT();
  }, []);

  const getdataSystem = async () => {
    try {
      const res = await API.get(
        `${SYS_LAYOUT}/all`
      );
      const data = res.data.data.result;
      if (data && res.data.data.message === "List successfully") {
        setDataDefault(data);
        const bannerSys = data.find(x => x.sys_key === "banner");
        const bannerLottery = data.find(x => x.sys_key === "bannerLottery");
        const bannerLotteryXSTT = data.find(x => x.sys_key === "bannerLotteryXSTT");
        const bannerOriginals = data.find(x => x.sys_key === "bannerOriginals");
        const logoSys = data.find(x => x.sys_key === "logo");
        const filmSys = data.find(x => x.sys_key === "film");
        const livestreamSys = data.find(x => x.sys_key === "livestream");
        const livestreamShowSys = data.find(x => x.sys_key === "livestreamShow");
        const watchFilmShowSys = data.find(x => x.sys_key === "watchFilmShow");
        setLivestreamShow(livestreamShowSys?.sys_value);
        setWatchFilmShow(watchFilmShowSys?.sys_value);
        const footerSys = data.find(x => x?.sys_key === "footer");

        const bannerList = bannerSys?.sys_value?.split(", ");
        const dataBanner = [];
          if(bannerList && bannerList.length > 0 && bannerList[0] != "") {
            bannerList?.map((ban, index) => {
                const itemFm = {
                  uid: `-${index + 1}`,
                  name: `banner${index + 1}.png`,
                  status: 'done',
                  url: ban,
                }
                dataBanner.push(itemFm);
            }) ;
          }
        
        setBanners(dataBanner);

        const bannerLtList = bannerLottery?.sys_value?.split(", ");
        const dataBannerLt = [];
        bannerLtList?.map((ban, index) => {
          const itemFm = {
            uid: `-${index + 1}`,
            name: `banner-lt${index + 1}.png`,
            status: 'done',
            url: ban,
          }
          dataBannerLt.push(itemFm);
        });
        setBannerLottery(dataBannerLt);

        const bannerLtListXSTT = bannerLotteryXSTT?.sys_value?.split(", ");
        const dataBannerLtXSTT = [];
        bannerLtListXSTT?.map((ban, index) => {
          const itemFm = {
            uid: `-${index + 1}`,
            name: `banner-lt-xstt${index + 1}.png`,
            status: 'done',
            url: ban,
          }
          dataBannerLtXSTT.push(itemFm);
        });
        setBannerLotteryXSTT(dataBannerLtXSTT);

        const bannerOrList = bannerOriginals?.sys_value?.split(", ");
        const dataBannerOr = [];
        bannerOrList?.map((ban, index) => {
          const itemFm = {
            uid: `-${index + 1}`,
            name: `banner-or${index + 1}.png`,
            status: 'done',
            url: ban,
          }
          dataBannerOr.push(itemFm);
        });
        setBannerOriginals(dataBannerOr);

        setLogoView(logoSys?.sys_value);

        setFilm(filmSys?.sys_value);

        const liveList = livestreamSys?.sys_value?.split(", ");
        const dataLives = [];
        liveList?.map((rom, index) => {
          const itemFm = {
            id: index + 1,
            url: rom
          }
          dataLives.push(itemFm);
        });
        setLivestreams(dataLives);
        setLiveUp(dataLives);

        const dataFooter = JSON.parse(footerSys?.sys_value);
        setUiFooter({
          logo: dataFooter?.logo,
          content: dataFooter?.content
        })
        setLogoFtView(dataFooter?.logo);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getDataGame = async () => {
    try {
      const res = await API.get(
        `${GAME}/all/-1`
      );
      const data = res.data.data.result;
      if (data) {
        setGames(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getGamesXSTT = async () => {
    try {
      const res = await API.get(`${GAME}/xstt`);
      const data = res?.data?.data;
      const groupedItems = data.reduce((group, item) => {
        const { parrentType } = item;

        group[parrentType] = group[parrentType] ?? [];
        group[parrentType].push(item);

        return group;
      }, {});
      const convertObjToArr = Object.keys(groupedItems)?.map((key) => [key, groupedItems[key]]);
      setGamesXSTT(convertObjToArr);
    } catch (err) {
      console.log(err);
    }
  };

  // const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  //   const byteCharacters = atob(b64Data);
  //   const byteArrays = [];

  //   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
  //     const slice = byteCharacters.slice(offset, offset + sliceSize);

  //     const byteNumbers = new Array(slice.length);
  //     for (let i = 0; i < slice.length; i++) {
  //       byteNumbers[i] = slice.charCodeAt(i);
  //     }

  //     const byteArray = new Uint8Array(byteNumbers);
  //     byteArrays.push(byteArray);
  //   }

  //   const blob = new Blob(byteArrays, { type: contentType });
  //   return blob;
  // }

  const getUrlS3Banner = async (file) => {
    // const indexTmp = file?.thumbUrl.toString().indexOf("64,");
    // const b64Data = file?.thumbUrl.substring(indexTmp + 3);
    // const fileImg = b64toBlob(b64Data, file?.type);
    // fileImg.name = file?.name

    const formData = new FormData();
    formData.append("image", file?.originFileObj);
    try {
      const response = await API.post(`${SYS_LAYOUT}/upload`, formData);
      if (response?.data?.data.url) {
        const newImg = {
          uid: file?.uid,
          name: file?.name,
          status: 'done',
          url: response?.data?.data?.url,
        }
        // const newArr = banners.map(obj => (obj.uid === file?.uid ? newImg : obj));
        return newImg;
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getUrlS3Img = async (file) => {
    // const indexTmp = dataBase64.toString().indexOf("64,");
    // const b64Data = dataBase64.substring(indexTmp + 3);
    // const fileImg = b64toBlob(b64Data, "image/png");
    const formData = new FormData();
    formData.append("image", file);
    try {
      const response = await API.post(`${SYS_LAYOUT}/upload`, formData);
      if (response?.data?.data.url) {
        return response?.data?.data.url;
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleSubmit = async () => {
    let url, parmas;
    if (keyUp === "games") {
      if (gamesUp.length === 0) {
        setIsShowModalConfirm(false);
        setIsShowModalSuccess(true);
      }

      setLoading(true)
      const listUrl = [], listParam = [];

      gamesUp?.map((game) => {
        const url = `${GAME}/${game.id}`;
        const param = {
          category: game?.category,
          parrentType: game?.parrentType,
          type: game?.type,
          sumBet: Number(game?.sumBet),
          textView: game?.textView,
          image: game?.image,
        }
        listUrl.push(url);
        listParam.push(param);
      })

      let totalTime = listUrl.length * 1000
      let indexaaa = 0
      const interval = setInterval(() => {
        if (totalTime > 0) {
          API.patch(listUrl[indexaaa], listParam[indexaaa])
          indexaaa = indexaaa + 1
          totalTime = totalTime - 1000
        } else {
          setIsShowModalConfirm(false);
          setIsShowModalSuccess(true);
          clearInterval(interval);
          setLoading(false)
        }
      }, 1000);
      return () => clearInterval(interval);
    } else {
      if (isRequest.current) {
        return;
      };

      isRequest.current = true;

      switch (keyUp) {
        case "banner":
          url = `${SYS_LAYOUT}/${dataDefault?.find(x => x?.sys_key === "banner")?.id}`;
          let listBannerAll;
          const imagesNew = banners.filter(x => x.originFileObj);
          if (imagesNew?.length > 0) {
            listBannerAll = banners;
            const getUrl = imagesNew?.map(async img => {
              const getUrlImg = await getUrlS3Banner(img);
              listBannerAll = listBannerAll.filter(it => it.uid !== img.uid)
              listBannerAll.push(getUrlImg);
              const valueBanner = listBannerAll?.map(obj => obj.url).join(', ');
              parmas = {
                sys_key: "banner",
                sys_value: valueBanner,
              }
            })
            await Promise.all(getUrl);
          } else {
            listBannerAll = banners;
            const valueBanner = listBannerAll?.map(obj => obj.url).join(', ');
            parmas = {
              sys_key: "banner",
              sys_value: valueBanner,
            }
          }
          break;
        case "bannerLottery":
          url = `${SYS_LAYOUT}/${dataDefault?.find(x => x?.sys_key === "bannerLottery")?.id}`;
          let listBannerLtAll;
          const imagesNewLt = bannerLottery.filter(x => x.originFileObj);
          if (imagesNewLt?.length > 0) {
            listBannerLtAll = bannerLottery;
            const getUrl = imagesNewLt?.map(async img => {
              const getUrlImg = await getUrlS3Banner(img);
              listBannerLtAll = listBannerLtAll.filter(it => it.uid !== img.uid)
              listBannerLtAll.push(getUrlImg);
              const valueBanner = listBannerLtAll?.map(obj => obj.url).join(', ');
              parmas = {
                sys_key: "bannerLottery",
                sys_value: valueBanner,
              }
            })
            await Promise.all(getUrl);
          } else {
            listBannerLtAll = bannerLottery;
            const valueBanner = listBannerLtAll?.map(obj => obj.url).join(', ');
            parmas = {
              sys_key: "bannerLottery",
              sys_value: valueBanner,
            }
          }
          break;
        
        case "bannerLotteryXSTT":
          let id = dataDefault?.find(x => x?.sys_key === "bannerLotteryXSTT")?.id;
          if (!id) {
            id = 0;
          }
          let imageCopy;
          const originFileObj = bannerLotteryXSTT.filter(x => x.originFileObj);
          if (originFileObj?.length > 0) {
            imageCopy = bannerLotteryXSTT;
            for (const img of originFileObj) {
              const URLresponseFromS3 = await getUrlS3Banner(img);
              imageCopy = imageCopy.filter(it => it.uid !== img.uid)
              imageCopy.push(URLresponseFromS3);
              const valueBanner = imageCopy?.map(obj => obj.url).join(', ');
              parmas = {
                sys_key: "bannerLotteryXSTT",
                sys_value: valueBanner,
              }
            }
          } else {
            parmas = {
              sys_key: "bannerLotteryXSTT",
              sys_value: "",
            }
          }
          url = `${SYS_LAYOUT}/${id}`;
          break;
        
        case "bannerOriginals":
          url = `${SYS_LAYOUT}/${dataDefault?.find(x => x?.sys_key === "bannerOriginals")?.id}`;
          let listBannerOrAll;
          const imagesNewOr = bannerOriginals.filter(x => x.originFileObj);
          if (imagesNewOr?.length > 0) {
            listBannerOrAll = bannerOriginals;
            const getUrl = imagesNewOr?.map(async img => {
              const getUrlImg = await getUrlS3Banner(img);
              listBannerOrAll = listBannerOrAll.filter(it => it.uid !== img.uid)
              listBannerOrAll.push(getUrlImg);
              const valueBanner = listBannerOrAll?.map(obj => obj.url).join(', ');
              parmas = {
                sys_key: "bannerOriginals",
                sys_value: valueBanner,
              }
            })
            await Promise.all(getUrl);
          } else {
            listBannerOrAll = bannerOriginals;
            const valueBanner = listBannerOrAll?.map(obj => obj.url).join(', ');
            parmas = {
              sys_key: "bannerOriginals",
              sys_value: valueBanner,
            }
          }
          break;
        case "logo":
          if (logoView.indexOf("64,") === -1) {
            setIsShowModalConfirm(false);
            return;
          }
          url = `${SYS_LAYOUT}/${dataDefault?.find(x => x?.sys_key === "logo")?.id}`;
          const getUrlImg = await getUrlS3Img(logo)
          parmas = {
            sys_key: "logo",
            sys_value: getUrlImg,
          }
          break;
        case "film":
          url = `${SYS_LAYOUT}/${dataDefault?.find(x => x?.sys_key === "film")?.id}`;
          parmas = {
            sys_key: "film",
            sys_value: film.trim(),
          }
          break;
        case "livestream":
          url = `${SYS_LAYOUT}/${dataDefault?.find(x => x?.sys_key === "livestream")?.id}`;
          const valueLive = liveUp?.map(obj => obj.url).join(', ');
          parmas = {
            sys_key: "livestream",
            sys_value: valueLive,
          }
          break;
        case "livestreamShow":
          url = `${SYS_LAYOUT}/${dataDefault?.find(x => x?.sys_key === "livestreamShow")?.id}`;
          parmas = {
            sys_key: "livestreamShow",
            sys_value: livestreamShow,
          }
          break;
        case "watchFilmShow":
          url = `${SYS_LAYOUT}/${dataDefault?.find(x => x?.sys_key === "watchFilmShow")?.id}`;
          parmas = {
            sys_key: "watchFilmShow",
            sys_value: watchFilmShow,
          }
          break;
        case "footer":
          let uiFt;
          if (logoFtView.indexOf("64,") > -1) {
            const getUrlImg = await getUrlS3Img(uiFooter?.logo);
            uiFt = {
              logo: getUrlImg,
              content: uiFooter?.content
            }
          } else {
            uiFt = {
              logo: logoFtView,
              content: uiFooter?.content
            }
          }
          url = `${SYS_LAYOUT}/${dataDefault?.find(x => x?.sys_key === "footer")?.id}`;
          const valueFt = JSON.stringify(uiFt)
          parmas = {
            sys_key: "footer",
            sys_value: valueFt,
          }
          break;
        default: break;
      }
    }

    try {
      var response;
      response = await API.patch(url, parmas);
      setIsShowModalConfirm(false);
      isRequest.current = false;
      if (response?.data?.data.code === 20004) {
        setIsShowModalSuccess(true);
      }
    } catch (err) {
      isRequest.current = false;
      console.log(err);
    }
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = ({ fileList: newFileList }) => {
    setBanners(newFileList);
  }

  const handleChangeBnLt = ({ fileList: newFileList }) => {
    setBannerLottery(newFileList);
  }

  const handleBannerXSTT = ({ fileList: newFileList }) => {
    setBannerLotteryXSTT(newFileList);
  }

  const handleChangeBnOr = ({ fileList: newFileList }) => {
    setBannerOriginals(newFileList);
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const handleChangeLogo = (info) => {
    setLogo(info.file)
    getBase64Logo(info.file, (url) => {
      setLogoView(url);
    });
  };

  const handleChangeLogoFt = (info) => {
    const newFt = {
      logo: info.file,
      content: uiFooter?.content,
    }
    setUiFooter(newFt);
    getBase64Logo(info.file, (url) => {
      setLogoFtView(url);
    });
  };

  const onConfirm = (type) => {
    setIsShowModalConfirm(true);
    setKeyUp(type)
  }

  const onChangeContentFt = (e) => {
    const ft = {
      logo: uiFooter?.logo,
      content: e?.target?.value
    }

    setUiFooter(ft);
  }

  const changeUrlLive = (e, live) => {
    const liveDataDf = liveUp;
    let liveFind = liveDataDf?.find(x => x.id === live.id);
    liveFind = { id: live?.id, url: e?.target?.value };
    const newLives = liveDataDf?.map(l => (l.id === liveFind?.id ? liveFind : l));
    setLiveUp(newLives)
  }

  const handleChangeLivestreamShow = () => {
    setLivestreamShow(livestreamShow == 1 ? 2 : 1);
    onConfirm("livestreamShow");
  }

  const handleFilmshow = () => {
    const status = watchFilmShow == 1 ? 2 : 1;
    setWatchFilmShow(status);
    onConfirm("watchFilmShow");
  }

  const handleChangeImgGame = async (item, info) => {
    const getImgS3 = await getUrlS3Img(info.file)
    const dataDf = games;
    const dataChange = dataDf.find(x => x.id === item.id);
    dataChange.image = getImgS3;
    const listUp = gamesUp.filter(x => x.id !== item.id);
    listUp.push(dataChange);
    setGamesUp(listUp);
    setIsChangeGame(!isChangeGames)
  };

  const handleChangeImgGameXSTT = async (item, info) => {
    const URLResponse = await getUrlS3Img(info.file);
    const dataDf = gamesXSTT;
    const dataChange = dataDf.flatMap(([key, objects]) => objects).find((x) => x.id === item.id);
    if (dataChange) {
      dataChange.image = URLResponse;
      const listUp = gamesUp.filter((x) => x.id !== item.id);
      listUp.push(dataChange);
      setGamesUp(listUp);
      setIsChangeGame(!isChangeGames);
    }
  };

  useEffect(() => { }, [isChangeGames]);

  const updateGameName = (item, value) => {
    const dataDf = games;
    const dataChange = dataDf.find(x => x.id === item.id);
    dataChange.textView = value;
    const listUp = gamesUp.filter(x => x.id !== item.id);
    listUp.push(dataChange);
    setGamesUp(listUp);
  }

  const updateGameNameXSTT = (item, value) => {
    const dataContainer = [];
    const dataDf = gamesXSTT.flatMap((items) => items[1]);
    dataContainer.push(...dataDf);

    const dataChange = dataContainer.find((x) => x.id === item.id);
    dataChange.textView = value;
    const listUp = gamesUp.filter(x => x.id !== item.id);
    listUp.push(dataChange);
    setGamesUp(listUp);
  }

	const renderLotteryGame = () => {
		const listGameLottery = games.filter((item) => item?.category !== 'casino');

		return (
      <>
        <div
          className={`flex w-full mt-4`}
          style={{
            borderBottom: '1px solid #CCC',
            paddingBottom: '2rem',
            marginBottom: '2rem',
          }}
        >
          <div className="flex flex-col w-full">
            <div className="flex w-[80%]">
              {/* <div className="w-[20%] flex justify-start">{TEXT_DEF.text0199}</div> */}
              <div className="w-[80%] mx-4">
                {listGameLottery?.map((game) => (
                  <div className="flex w-[100%]" key={game?.id}>
                    <div className="w-[30%]">
                      <Upload name="avatar" listType="picture-card" className="avatar-uploader" showUploadList={false} beforeUpload={() => false} onChange={(e) => handleChangeImgGame(game, e)}>
                        {game?.image ? (
                          <img
                            src={gamesUp?.find((x) => x.id === game?.id)?.image ? gamesUp?.find((x) => x.id === game?.id)?.image : game?.image}
                            alt="avatar"
                            style={{
                              width: '100%',
                            }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </div>
                    <div className="w-[20%] flex justify-start ml-1">{TEXT_DEF.text0039}</div>
                    <div className="w-[30%]">
                      <Input defaultValue={game?.textView} maxLength={255} onChange={(e) => updateGameName(game, e?.target?.value)} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {hasRight([RIGHTS.EditSettingLayout]) ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button htmlType="submit" type="primary" onClick={() => onConfirm('games')}>
                {TEXT_DEF.text0030}
              </Button>
            </div>
          ) : null}
        </div>
      </>
    );
  };

  const renderOriginalsGame = () => {
    const listGameOriginals = games.filter((item) => item?.category === 'casino');

    return (
      <>
        <div
          className={`flex w-full mt-4`}
          style={{
            borderBottom: '1px solid #CCC',
            paddingBottom: '2rem',
            marginBottom: '2rem',
          }}
        >
          <div className="flex flex-col w-full">
            <div className="flex w-[80%]">
              {/* <div className="w-[20%] flex justify-start">{TEXT_DEF.text0199}</div> */}
              <div className="w-[80%] mx-4">
                {listGameOriginals?.map((game) => (
                  <div className="flex w-[100%]" key={game?.id}>
                    <div className="w-[30%]">
                      <Upload name="avatar" listType="picture-card" className="avatar-uploader" showUploadList={false} beforeUpload={() => false} onChange={(e) => handleChangeImgGame(game, e)}>
                        {game?.image ? (
                          <img
                            src={gamesUp?.find((x) => x.id === game?.id)?.image ? gamesUp?.find((x) => x.id === game?.id)?.image : game?.image}
                            alt="avatar"
                            style={{
                              width: '100%',
                            }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </div>
                    <div className="w-[20%] flex justify-start ml-1">{TEXT_DEF.text0039}</div>
                    <div className="w-[30%]">
                      <Input defaultValue={game?.textView} maxLength={255} onChange={(e) => updateGameName(game, e?.target?.value)} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {hasRight([RIGHTS.EditSettingLayout]) ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button htmlType="submit" type="primary" onClick={() => onConfirm('games')}>
                {TEXT_DEF.text0030}
              </Button>
            </div>
          ) : null}
        </div>
      </>
    );
  };

	// const handleDisplayTitle = (key) => {
  //   if (key === 'xsmb') {
  //     return;
  //   }
  //   return classicTitle[key];
  // };

  const renderClassicLottery = () => {
    return (
      <>
        <div class="container mt-4">
          {gamesXSTT.map((items) => (
            <div className="row mb-3">
              <div className="col">
                <h6>{classicTitle[items[0]]}</h6>
              </div>
              <div className="row flex items-center justify-start gap-3">
                {items[1].map((el) => (
                  <div className="col-md-3">
                    <div className="flex w-[100%]" key={el?.id}>
                      <div>
                        <Upload name="avatar" listType="picture-card" className="avatar-uploader" showUploadList={false} beforeUpload={() => false} onChange={(e) => handleChangeImgGameXSTT(el, e)}>
                          {el?.image ? (
                            <img
                              src={gamesUp?.find((x) => x.id === el?.id)?.image ? gamesUp?.find((x) => x.id === el?.id)?.image : el?.image}
                              alt="avatar"
                              style={{
                                width: '100%',
                              }}
                            />
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      </div>
                      <div className="flex items-start justify-center flex-col">
                        <div className="">{TEXT_DEF.text0039}</div>
                        <div className="">
                          <Input defaultValue={el?.textView} maxLength={255} onChange={(e) => updateGameNameXSTT(el, e?.target?.value)} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
          {hasRight([RIGHTS.EditSettingLayout]) ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button htmlType="submit" type="primary" onClick={() => onConfirm('games')}>
                {TEXT_DEF.text0030}
              </Button>
            </div>
          ) : null}
        </div>
      </>
    );
  };

  const renderBannerLogo = () => {
    return (
      <>
        {/* Banner */}
        <div
          className={`flex w-full mt-4`}
          style={{
            borderBottom: '1px solid #CCC',
            paddingBottom: '2rem',
            marginBottom: '2rem',
          }}
        >
          <div className="flex flex-col w-full">
            <div className="flex w-[80%]">
              <div className="w-[20%] flex justify-start">{TEXT_DEF.text0035}</div>
              <div className="w-[80%] mx-4">
                <Upload listType="picture-card" fileList={banners.length > 0 ? banners : null} beforeUpload={() => false} onPreview={handlePreview} onChange={handleChange}>
                  {banners.length >= 8 ? null : uploadButton}
                </Upload>
                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                  <img
                    alt="example"
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </Modal>
              </div>
            </div>
          </div>
          {hasRight([RIGHTS.EditSettingLayout]) ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button htmlType="submit" type="primary" onClick={() => onConfirm('banner')}>
                {TEXT_DEF.text0030}
              </Button>
            </div>
          ) : null}
        </div>

        {/* Banner Xổ Số */}
        <div
          className={`flex w-full mt-4`}
          style={{
            borderBottom: '1px solid #CCC',
            paddingBottom: '2rem',
            marginBottom: '2rem',
          }}
        >
          <div className="flex flex-col w-full">
            <div className="flex w-[80%]">
              <div className="w-[20%] flex justify-start">Banner Xổ số</div>
              <div className="w-[80%] mx-4">
                <Upload listType="picture-card" fileList={bannerLottery} beforeUpload={() => false} onPreview={handlePreview} onChange={handleChangeBnLt}>
                  {bannerLottery.length >= 8 ? null : uploadButton}
                </Upload>
                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                  <img
                    alt="example"
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </Modal>
              </div>
            </div>
          </div>
          {hasRight([RIGHTS.EditSettingLayout]) ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button htmlType="submit" type="primary" onClick={() => onConfirm('bannerLottery')}>
                {TEXT_DEF.text0030}
              </Button>
            </div>
          ) : null}
        </div>

        {/* Banner Xổ số TT */}
        {/* <div
          className={`flex w-full mt-4`}
          style={{
            borderBottom: '1px solid #CCC',
            paddingBottom: '2rem',
            marginBottom: '2rem',
          }}
        >
          <div className="flex flex-col w-full">
            <div className="flex w-[80%]">
              <div className="w-[20%] flex justify-start">Banner Xổ số TT</div>
              <div className="w-[80%] mx-4">
                <Upload listType="picture-card" fileList={bannerLotteryXSTT} beforeUpload={() => false} onPreview={handlePreview} onChange={handleBannerXSTT}>
                  {bannerLotteryXSTT.length >= 8 ? null : uploadButton}
                </Upload>
                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                  <img
                    alt="example"
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </Modal>
              </div>
            </div>
          </div>
          {hasRight([RIGHTS.EditSettingLayout]) ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button htmlType="submit" type="primary" onClick={() => onConfirm('bannerLotteryXSTT')}>
                {TEXT_DEF.text0030}
              </Button>
            </div>
          ) : null}
        </div> */}

        {/* Banner Originals */}
        <div
          className={`flex w-full mt-4`}
          style={{
            borderBottom: '1px solid #CCC',
            paddingBottom: '2rem',
            marginBottom: '2rem',
          }}
        >
          <div className="flex flex-col w-full">
            <div className="flex w-[80%]">
              <div className="w-[20%] flex justify-start">Banner Originals</div>
              <div className="w-[80%] mx-4">
                <Upload listType="picture-card" fileList={bannerOriginals} beforeUpload={() => false} onPreview={handlePreview} onChange={handleChangeBnOr}>
                  {bannerOriginals.length >= 8 ? null : uploadButton}
                </Upload>
                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                  <img
                    alt="example"
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </Modal>
              </div>
            </div>
          </div>
          {hasRight([RIGHTS.EditSettingLayout]) ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button htmlType="submit" type="primary" onClick={() => onConfirm('bannerOriginals')}>
                {TEXT_DEF.text0030}
              </Button>
            </div>
          ) : null}
        </div>

        {/* Logo */}
        <div
          className={`flex w-full mt-4`}
          style={{
            borderBottom: '1px solid #CCC',
            paddingBottom: '2rem',
            marginBottom: '2rem',
          }}
        >
          <div className="flex flex-col w-full">
            <div className="flex w-[80%]">
              <div className="w-[20%] flex justify-start">{TEXT_DEF.text0037}</div>
              <div className="w-[80%] mx-4">
                <Upload name="avatar" listType="picture-card" className="avatar-uploader" showUploadList={false} beforeUpload={() => false} onChange={handleChangeLogo}>
                  {logoView ? (
                    <img
                      src={logoView}
                      alt="avatar"
                      style={{
                        width: '100%',
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </div>
            </div>
          </div>
          {hasRight([RIGHTS.EditSettingLayout]) ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button htmlType="submit" type="primary" onClick={() => onConfirm('logo')}>
                {TEXT_DEF.text0030}
              </Button>
            </div>
          ) : null}
        </div>

        {/* Footer */}
        <div className={`flex w-full mt-4`}>
          <div className="flex flex-col w-full">
            <div className="flex w-[80%]">
              <div className="w-[20%] flex justify-start">{'Footer'}</div>
              <div className="w-[80%] mx-4">
                <div className="flex w-[100%]">
                  <div className="w-[20%] flex justify-start">Logo</div>
                  <div className="w-[30%]">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                      beforeUpload={() => false}
                      onChange={handleChangeLogoFt}
                    >
                      {logoFtView ? (
                        <img
                          src={logoFtView}
                          alt="logo"
                          style={{
                            width: '100%',
                          }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </div>
                </div>
                <div className="flex w-[100%]">
                  <div className="w-[20%] flex justify-start ml-1">{'Nội dung'}</div>
                  <div className="w-[80%]">
                    <TextArea value={uiFooter?.content} rows={6} onChange={(e) => onChangeContentFt(e)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {hasRight([RIGHTS.EditSettingLayout]) ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button htmlType="submit" type="primary" onClick={() => onConfirm('footer')}>
                {TEXT_DEF.text0030}
              </Button>
            </div>
          ) : null}
        </div>
      </>
    );
  };

  const renderListGames = () => {
    const items = [
      {
        key: '1',
        label: 'XSN',
        children: renderLotteryGame(),
      },
      {
        key: '2',
        label: 'Originals',
        children: renderOriginalsGame(),
      },
      {
        key: '3',
        label: 'XSTT',
        children: renderClassicLottery(),
      },
    ];

    return (
      <>
        <Tabs defaultActiveKey="1" items={items} />
      </>
    );
  };

  const renderLinks = () => {
    return (
      <>
        <div
          className={`flex w-full mt-4`}
          style={{
            borderBottom: '1px solid #CCC',
            paddingBottom: '2rem',
            marginBottom: '2rem',
          }}
        >
          <div className="flex w-[100%]">
            <div className="w-[20%] flex justify-start flex-column">
              {TEXT_DEF.text0044}
              <div>
                <Switch onChange={() => handleFilmshow()} checked={watchFilmShow == 1 ? true : false} />
              </div>
            </div>
            <div className="w-[80%] mx-4 flex">
              <div className="w-[20%] flex justify-start">{TEXT_DEF.text0046}</div>
              <div className="w-[70%]">
                <Input defaultValue={film} value={film} onChange={(e) => setFilm(e?.target?.value)} />
              </div>
            </div>
          </div>
          {hasRight([RIGHTS.EditSettingLayout]) ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button htmlType="submit" type="primary" onClick={() => onConfirm('film')}>
                {TEXT_DEF.text0030}
              </Button>
            </div>
          ) : null}
        </div>

        <div
          className={`flex w-full mt-4`}
          style={{
            borderBottom: '1px solid #CCC',
            paddingBottom: '2rem',
            marginBottom: '2rem',
          }}
        >
          <div className="flex w-[100%]">
            <div className="w-[20%] flex justify-start flex-column">
              {TEXT_DEF.text0045}
              <div>
                <Switch onChange={(event) => handleChangeLivestreamShow()} checked={livestreamShow == 1 ? true : false} />
              </div>
            </div>
            <div className="w-[80%] mx-4">
              {livetreams?.map((live, index) => (
                <div key={live?.id} className="w-[100%] flex" style={{ marginTop: index === 0 ? 0 : 10 }}>
                  <div className="w-[20%] flex justify-start">{TEXT_DEF.text0046}</div>
                  <div className="w-[70%]">
                    <Input defaultValue={live?.url} onChange={(e) => changeUrlLive(e, live)} />
                  </div>
                </div>
              ))}
            </div>
          </div>
          {hasRight([RIGHTS.EditSettingLayout]) ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button htmlType="submit" type="primary" onClick={() => onConfirm('livestream')}>
                {TEXT_DEF.text0030}
              </Button>
            </div>
          ) : null}
        </div>

        {/* show livestream */}
        {/* <div className={`flex w-full mt-4`}
            style={{
              borderBottom: "1px solid #CCC",
              paddingBottom: "2rem",
              marginBottom: "2rem",
            }}
          >
            <div className="flex w-[100%]">
              <div className="w-[40%] flex justify-start">
                Hiện Livestream trang chủ
              </div>
              <div className="w-[60%] mx-4">
                  <select onChange={(event) => handleChangeLivestreamShow(event.target.value)}>
                    <option value="1" selected={livestreamShow == 1}>Hiện</option>
                    <option value="2" selected={livestreamShow == 2}>Ẩn</option>
                  </select>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => onConfirm("livestreamShow")}
              >
                {TEXT_DEF.text0030}
              </Button>
            </div>
          </div> */}
        {/* show livestream */}
        {/* <div className={`flex w-full mt-4`}
            style={{
              borderBottom: "1px solid #CCC",
              paddingBottom: "2rem",
              marginBottom: "2rem",
            }}
          >
            <div className="flex w-[100%]">
              <div className="w-[40%] flex justify-start">
                Hiện xem phim trang chủ
              </div>
              <div className="w-[60%] mx-4">
                  <select onChange={(event) => setWatchFilmShow(event.target.value)}>
                    <option value="1" selected={watchFilmShow == 1}>Hiện</option>
                    <option value="2" selected={watchFilmShow == 2}>Ẩn</option>
                  </select>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => onConfirm("watchFilmShow")}
              >
                {TEXT_DEF.text0030}
              </Button>
            </div>
          </div> */}
      </>
    );
  };

  const items = [
    {
      key: '1',
      label: 'Banner & Logo',
      children: renderBannerLogo(),
    },
    {
      key: '2',
      label: 'Danh sách trò chơi',
      children: renderListGames(),
    },
    {
      key: '3',
      label: 'URL liên kết',
      children: renderLinks(),
    },
  ];

  return (
    <>
      <Spin spinning={loading} delay={500} size="large">
        <div className="col-12">
          <div className="col-12">
            <Tabs defaultActiveKey="1" items={items} />
          </div>
          {isShowModalConfirm && (
            <ModalCommon isShow={isShowModalConfirm} message={TEXT_DEF.text0071} txtBtnAcc={TEXT_DEF.text0008} textBtnClose={TEXT_DEF.text0072} actionNext={handleSubmit} close={() => setIsShowModalConfirm(false)} />
          )}
          {isShowModalSuccess && (
            <ModalCommon
              isShow={isShowModalSuccess}
              message={TEXT_DEF.text0073}
              txtBtnAcc={TEXT_DEF.text0007}
              actionNext={() => {
                setIsShowModalSuccess(false);
              }}
              close={() => {
                setIsShowModalSuccess(false);
              }}
            />
          )}
        </div>
      </Spin>
    </>
  );
};

export default LayoutConfig;