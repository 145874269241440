import React, { useEffect, useState } from "react";
import { TEXT_DEF } from "../../../constant/text";
import { Button } from "antd";
import ModalCommon from "../../../components/Modal";
import API, { ADMIN_MINES } from "../../../network/api";
import { RIGHTS } from "../../../constant/rights";
import { hasRight } from "../../../components/until/right";
import CustomInputNumber from "../../../components/common/CustomInputNumber";
import RenderSwitch from "../../../components/common/Switch";
import { ADMIN_TAIXIU } from '../../../network/api';

const TaiXiuSetting = () => {
  const [isMaintenance, setIsMaintenance] = useState()
  const [dataUp, setDataUp] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [valueToUpdate, setValueToUpdate] = useState({});
  const labels = {
    ratesWin: 'Tỉ lệ lợi nhuận',
    tai: 'Tài',
    xiu: 'Xỉu',
    chan: 'Chẵn',
    le: 'Lẻ',
    '3-1': 'Tam bảo 1',
    '3-2': 'Tam bảo 2',
    '3-3': 'Tam bảo 3',
    '3-4': 'Tam bảo 4',
    '3-5': 'Tam bảo 5',
    '3-6': 'Tam bảo 6',
    '3-any': 'Tam bảo bất kỳ',

  };
  const keysToFilter = ['isMaintenance', 'ratesWin'];

  useEffect(() => {
    const getDataTaiXiu = async () => {
      const response = await API.get(`${ADMIN_TAIXIU}/getConfig`);
      const resultGame = response.data?.data?.result;
      setData(resultGame);
    };

    getDataTaiXiu();
  }, []);

  const showConfirm = (key) => {
    let params;
    const id = data?.find(x => x.key === key)?.id;
    switch (key) {
      case "isMaintenance":
        params = {
          key: "isMaintenance",
          value: isMaintenance.toString()
        }
        break;
      case "ratesWin":
        params = {
          key: "ratesWin",
          value: valueToUpdate[key].toString()
        }
        break;
      case "tai":
        params = {
          key: "tai",
          value: valueToUpdate[key].toString()
        }
        break;
      case "xiu":
        params = {
          key: "xiu",
          value: valueToUpdate[key].toString()
        }
        break;
      case "chan":
        params = {
          key: "chan",
          value: valueToUpdate[key].toString()
        }
        break;
      case "le":
        params = {
          key: "le",
          value: valueToUpdate[key].toString()
        }
        break;
      case "3-1":
        params = {
          key: "3-1",
          value: valueToUpdate[key].toString()
        }
        break;
      case "3-2":
        params = {
          key: "3-2",
          value: valueToUpdate[key].toString()
        }
        break;
      case "3-3":
        params = {
          key: "3-3",
          value: valueToUpdate[key].toString()
        }
        break;
      case "3-4":
        params = {
          key: "3-4",
          value: valueToUpdate[key].toString()
        }
        break;
      case "3-5":
        params = {
          key: "3-5",
          value: valueToUpdate[key].toString()
        }
        break;
      case "3-6":
        params = {
          key: "3-6",
          value: valueToUpdate[key].toString()
        }
        break;
      case "3-any":
        params = {
          key: "3-any",
          value: valueToUpdate[key].toString()
        }
        break;
      default: break;
    }
    const dataReq = {
      id: id,
      params: params
    }
    setDataUp(dataReq);
    setIsShowModalConfirm(true)
  }

  const handleChangeSwitch = (e) => {
    setIsMaintenance(e);
  };

  const onRequest = async () => {
    if (!dataUp) return;
    await API.patch(`${ADMIN_TAIXIU}/updateConfig/${dataUp?.id}`, dataUp?.params)
      .then(response => {
        const result = response.data?.data;
        if (result?.code === 20004) {
          setLoading(true);
          setIsShowModalConfirm(false);
          setIsShowModalSuccess(true);
        }
      }).catch((error) => {
        console.log(error);
      })
  }

  const filteredData = data.filter((item) => item.key !== 'isMaintenance');

  const sortedRatio = filteredData.sort((a, b) => {
    const withRatio = keysToFilter.includes(a.key);
    const withoutRatio = keysToFilter.includes(b.key);

    if (withRatio && !withoutRatio) return -1;
    if (!withRatio && withoutRatio) return 1;

    return 0;
  });

  const handleGetKey = (value) => {
    showConfirm(value);
  };

  const handleInputChange = (key, newValue) => {
    setValueToUpdate((prev) => ({ ...prev, [key]: Number(newValue) }));
  };

  const renderInputFields = () => {
    if (!data || data.length === 0) return;

    return (
      <>
        {sortedRatio.length > 0 &&
          sortedRatio?.map((item, index) => (
            <>
              <div className="col-12 flex" key={index}>
                <div className="col-3 text-left">{labels[item?.key] || item.key}</div>
                <div className="col-6">
                  <CustomInputNumber min={0} max={9999} step={0.01} value={item?.value} onChange={(e) => handleInputChange(item.key, e)} />
                </div>
                {hasRight([RIGHTS.EditSettingOriginals]) ? (
                  <div className="col-3 text-left">
                    <Button
                      type="primary"
                      onClick={() => {
                        handleGetKey(item?.key);
                      }}
                    >
                      {TEXT_DEF.text0016}
                    </Button>
                  </div>
                ) : null}
              </div>
              <div style={{ margin: '10px 0px' }} />
            </>
          ))}
      </>
    );
  };

  return (
    <>
      {data ?
        <div className="col-12 flex-col"
          style={{ display: "flex", marginTop: 30, alignItems: "center" }}
        >
          <RenderSwitch data={data} labels={labels} handleChangeSwitch={handleChangeSwitch} hasRight={hasRight} handleGetKey={handleGetKey} />
          <div style={{ margin: "10px 0px" }} />
          {renderInputFields()}
          <div style={{ margin: "10px 0px" }} />
        </div> : null}
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text0071}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0072}
          actionNext={onRequest}
          close={() => { setIsShowModalConfirm(false); setDataUp(undefined) }}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text0073}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </>
  )
}

export default TaiXiuSetting;