import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import { Form, Button, DatePicker, Table, Spin, Pagination, Select } from 'antd';
import API, {
  BOOKMAKER,
  STATISTIC,
  GAME,
} from "../../../network/api";
import moment from "moment";
import "moment-timezone";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement } from 'chart.js';
import { getGame, getCategory, extractNumbers } from "../../../components/until";
import ModalChart from "../modal";
import { OP_CATEGORY, OP_GAME_ORIGINALS } from "../constant";
import {
  EMPTY_DATA,
  TEXT_DEF,
} from "../../../constant/text";
import { RIGHTS } from "../../../constant/rights";
import { hasRight } from "../../../components/until/right";
import { useTabs } from "../../../contexts/TabContext";
import dayjs from  'dayjs';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import {disabledTimeFrom, disabledTimeTo, validateDateRange} from "../../../utils/dateUtils";

const { MonthPicker } = DatePicker;


ChartJS.register(ArcElement, Tooltip, Legend, BarElement);

const ReportByBookmaker = () => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState(null);
  const [monthFrom, setMonthFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [monthTo, setMonthTo] = useState(null);
  const [indexTab, setIndexTab] = useState(1);
  localStorage.removeItem("indexTabAcc");
  const [loading, setLoading] = useState(false);
  const [searchBy, setSearchBy] = useState();
  const [dataChartModal, setDataChartModal] = useState(undefined);
  const [titleChartModal, setTitleChartModal] = useState(undefined);
  const [isShowModalChart, setIsShowModalChart] = useState(false);
  const [gameTypes, setGameTypes] = useState();
  const [gameTypesReq, setGameTypesReq] = useState();
  const [bookmarkers, setBookmarkers] = useState();
  const [opGameCategory, setOpGameCategory] = useState(OP_CATEGORY);
  const [gameCategory, setGameCategory] = useState("");
  const [game, setGame] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [bookmarkerSelected, setBookmarkerSelected] = useState(1);
  const [ordersInfo, setOrdersInfo] = useState([]);
  const [finalInfo, setFinalInfo] = useState({
    allUser: 0,
    allNewUser: 0,
    allOrders: 0,
    allBet: 0,
    allAmountUserWin: 0,
    allProfit: 0,
    allPercentProfit: 0,
  });
  const { addTab } = useTabs();


  useEffect(() => {
    forceUpdate({});
  }, []);

  // useEffect(() => {
  //   form.setFieldValue('dateTo', dayjs())
  //   form.setFieldValue('dateFrom', dayjs().add(-3, 'month'));
  //   onSearch();
  // }, [])

  useEffect(() => {
    form.setFieldValue("gameType", undefined)
    setGame(undefined);
    if (gameCategory === "lottery") {
      getGameTypes(0);
    } else if (gameCategory === "casino") {
      getGameTypes(1);
    } else {
      getGameTypes(-1);
    }
  }, [gameCategory]);

  useEffect(() => {
    getBookmarkers();
  }, []);

  const getGameCategory = () => {
    return gameCategory ? getGameCategory : null;
  }

  useEffect(() => {
    getOrders();
  }, [page, pageSize]);

  const colums = [
    {
      title: "STT",
      align: "center",
      key: "order",
      render: (text, record, index) => (
        <span>{(page - 1) * pageSize + (index + 1)}</span>
      ),
    },
    // {
    //   title: "Loại tổng hợp",
    //   dataIndex: "typeSl",
    //   key: "typeSl",
    // },
    {
      title: TEXT_DEF.text0011,
      dataIndex: "bookmarkerName",
      key: "bookmarkerName",
    },
    {
      title: TEXT_DEF.text0160,
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Tổng user",
      dataIndex: "totalUsers",
      key: "totalUsers",
    },
    {
      title: "User mới",
      dataIndex: "newUsers",
      key: "newUsers",
    },
    {
      title: "Đơn cược",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Tiền cược",
      dataIndex: "totalBet",
      key: "totalBet",
    },
    {
      title: "Tiền khách thắng",
      dataIndex: "paymentWin",
      key: "paymentWin",
    },
    // {
    //   title: "Tiền khuyễn mãi",
    //   dataIndex: "bonus",
    //   key: "bonus",
    // },
    {
      title: "Lợi nhuận",
      dataIndex: "profit",
      key: "profit",
    },
    {
      title: "% thắng",
      dataIndex: "percentProfit",
      key: "percentProfit",
    },
    {
      title: 'Hành động',
      key: 'setting',
      align: 'center',
      render: (record) =>
        <div>
          {
            hasRight([RIGHTS.ShowReportOrdersByGame]) ? (
              <div className="flex w-full items-center justify-center" style={{ flexDirection: "column" }}>
                <div style={{ cursor: "pointer", color: "#1677ff" }} onClick={() => goToReportByGame(record)}>Thống kê theo game</div>
                {/* <div style={{ cursor: "pointer", color: "#1677ff" }} onClick={() => goToHistoryWallet(record)}>Thống kê theo nền tảng</div> */}
              </div>
            ) : null
          }
        </div>
    },
  ];

	const goToReportByGame = (bookmaker) => {
    bookmaker.searchBy = searchBy;
    bookmaker.game = game;
    bookmaker.gameSearch = gameTypesReq;
    addTab('/report-by-game', 'Trò chơi', {bookmaker})
    navigate("/report-by-game", {state: {bookmaker: bookmaker}});
  }

  const getGameTypes = async (type = null) => {
    const result = await API.get(`${GAME}/all/${type}`);
    if (!result?.data?.data?.result || result?.data?.data?.result.length === 0) {
      setGameTypes([]);
      return;
    }

    const gameTypesXSN = (result?.data?.data?.result || []).map((item) => {
      return {
        key: item?.id,
        value: item?.category === "xoso" ? `${item?.parrentType}-${extractNumbers(item?.type)}` : item?.parrentType,
        label: getGame(item?.parrentType, extractNumbers(item?.type)),
      };
    });
    const gameTypesXSTT = await getGameTypesXSTT();
    let allGames = gameTypesXSN;
    if (type !== 1) {
      allGames = [...gameTypesXSN, ...gameTypesXSTT];
    }
    setGameTypes(allGames);
  }

  const getGameTypesXSTT = async () => {
    const result = await API.get(`${GAME}/xstt`);
    if (!result?.data?.data || result?.data?.data?.length === 0) {
      return [];
    }

    const games = result?.data?.data || [];
    const { gamesMT, gamesMB, gamesMN } = games.reduce((init, currentValue) => {
      if (currentValue.parrentType === 'xsmt') {
        init.gamesMT.push(currentValue);
      } else if (currentValue.parrentType === 'xsmn') {
        init.gamesMN.push(currentValue);
      } else {
        init.gamesMB.push(currentValue);
      }

      return init;
    }, { gamesMT: [], gamesMB: [], gamesMN: [] });
    const gamesFinal = gamesMB.concat(gamesMT).concat(gamesMN);

    return gamesFinal.map((item) => {
      return {
        key: item?.id,
        value: `${item.lotteryType}_${item.parrentType}_${item.type}`,
        label: item.textView.includes('XS') ? item.textView.replace('XS', 'Xổ Số') : item.textView,
      };
    });
  };

  const getBookmarkers = async () => {
    const result = await API.get(`${BOOKMAKER}/all`);
    const bookmarkers = result.data?.data?.result[0] || [];
    if (!bookmarkers || bookmarkers.length === 0) return;

    const tempBookmarkers = bookmarkers?.map((item) => {
      return {
        key: item?.id,
        value: item?.id,
        label: item?.name,
      };
    });
    setBookmarkers(tempBookmarkers);
    setBookmarkerSelected(tempBookmarkers?.[0]?.key);
    initFormValues({
      bookmarker: tempBookmarkers?.[0]?.label,
    });
  };

  const initFormValues = (initData) => {
    if (!initData) return;

    setGameCategory("all");
    setSearchBy("day");
    for (const key in initData) {
      form.setFieldsValue({
        [key]: initData[key],
        gameCategory: "all",
        searchBy: "day",
      });
    }
  }

  const getOrders = async () => {
    try {
      const params = getParams();
      if (!params) return;

      setLoading(true);
      const result = await API.get(`${STATISTIC}?${params}`);
      const ordersInfo = result.data?.data?.ordersInfo || [];
      const sumOrders = result.data?.data?.sumOrders || [];
      const total = result.data?.data?.total || 0;
      const finalResult = [];
      setTotalRecord(total);
      let allUser = 0;
      let allNewUser = 0;
      let allOrders = 0;
      let allBet = 0;
      let allAmountUserWin = 0;
      let allProfit = 0;

      for (const item of ordersInfo) {
        // const profit = Number(item.totalBet || 0) - Number(item.paymentWin || 0);
        const profit = Number(item.bookmarkerProfit);
        const percentProfit = Number(((profit / Number(item.totalBet || 0)) * 100) || 0).toFixed(2);
        finalResult.push({
          profit: dollarUS.format(Number(profit)),
          percentProfit: 
            (Number(percentProfit) <= 100 && Number(percentProfit) > 0) || 
            (Number(percentProfit) >= -100 && Number(percentProfit) < 0) ? 
            percentProfit : Number(percentProfit) > 100 ? 100 : 0,
          bookmarkerProfit: item.bookmarkerProfit,
          count: item.count,
          newUsers: item.newUsers,
          paymentWin: dollarUS.format(Number(item.paymentWin)),
          time: item.time,
          totalBet: dollarUS.format(Number(item.totalBet)),
          totalUsers: item.totalUsers,
          bookmarkerName: item.bookmarkerName,
        });

        allUser = item.totalUsers || 0;
        allNewUser += Number(item.newUsers) || 0;
        allOrders += Number(item.count) || 0;
        allBet += Number(item.totalBet) || 0;
        allAmountUserWin += Number(item.paymentWin) || 0;
        allProfit += Number(profit)
      }
      let allPercentProfit = (!Number(allProfit) || !Number(allBet)) ? 0 : ((allProfit / allBet) * 100).toFixed(2);
      setFinalInfo({
        allUser: sumOrders?.totalUsers || 0,
        allNewUser: sumOrders?.totalNewUsers || 0,
        allOrders: sumOrders?.totalOrders || 0,
        allBet: sumOrders?.totalBet || 0,
        allAmountUserWin: sumOrders?.totalPaymentWin || 0,
        allProfit: sumOrders?.totalProfit || 0,
        allPercentProfit: sumOrders?.totalPercentProfit || 0,
      });
      setOrdersInfo(finalResult);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  const getParams = (isExport = false) => {
    if (!dateFrom || !dateTo || !monthFrom || !monthTo || !gameCategory || !bookmarkerSelected) return;
    let params = "";
    if (bookmarkerSelected) {
      params += `bookmarkerId=${bookmarkerSelected}`;
    }
    if (searchBy) {
      params += `&searchBy=${searchBy}`;
    }
    if (dateFrom) {
      params += `&fromDate=${moment(dateFrom).unix()}`;
    }
    if (dateTo) {
      params += `&toDate=${moment(dateTo).unix()}`;
    }
    if (game && game?.length > 0) {
      params += `&gameType=${game}`;
    }
    if (gameCategory) {
      params += `&game=${gameCategory}`;
      if (!game || game?.length === 0) {
        const dataGame = gameTypes.map(obj => obj.value).join(',');
        setGameTypesReq(dataGame)
        params += `&gameType=${dataGame}`;
      }
    }
		params += !isExport ? `&page=${page}&limit=${pageSize}` : `&page=1&limit=1000000`;
    return params;
  }

  const onSearch = async () => {
    setPage(1)
    await getOrders();
  };

  const dollarUS = Intl.NumberFormat("en-US");

  const onChangeDate = (e, type) => {
    if (e) {
			if (type === 'from') {
        setDateFrom(moment(e.$d).format('yyyy-MM-DD HH:mm:ss'));

        const monthFromOfYear = moment(e.$d).format('MM-YYYY');
        setMonthFrom(monthFromOfYear);
      } else {
        setDateTo(moment(e.$d).format('yyyy-MM-DD HH:mm:ss'));

        const monthToOfYear = moment(e.$d).format('MM-YYYY');
        setMonthTo(monthToOfYear);
      }
    } else {
      if (type === 'from') {
        setDateFrom(null);
        setMonthFrom(null);
      } else {
        setDateTo(null);
        setMonthTo(null);
      }
    }
  };

  const filterOptionBm = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onChangeTab = (e) => {
    setIndexTab(e.target.value);
    if (e.target.value == 2) {
      // getDataLotteryByGame(0)
    }
  };


  const onExportExl = async () => {
    try {
      const params = getParams(true);
      if (!params) return;

      setLoading(true);
      const result = await API.get(`${STATISTIC}?${params}`);
      const ordersInfo = result.data?.data?.ordersInfo || [];
      const total = result.data?.data?.total || 0;
      const finalResult = [];
      setTotalRecord(total);

      for (const item of ordersInfo) {
        const profit = Number(item.bookmarkerProfit);
        const percentProfit = Number(((profit / Number(item.totalBet || 0)) * 100) || 0).toFixed(2);
        finalResult.push({
          bookmarkerName: item.bookmarkerName,
          time: item.time,
          totalUsers: item.totalUsers,
          newUsers: item.newUsers,
          count: item.count,
          // totalBet: dollarUS.format(Number(item.totalBet)),
          totalBet: Number(item.totalBet),
          // paymentWin: dollarUS.format(Number(item.paymentWin)),
          paymentWin: Number(item.paymentWin),
          // profit: dollarUS.format(Number(profit)),
          profit: profit,
          percentProfit: 
            (Number(percentProfit) <= 100 && Number(percentProfit) > 0) || 
            (Number(percentProfit) >= -100 && Number(percentProfit) < 0) ? 
            percentProfit : Number(percentProfit) > 100 ? 100 : 0,
        });
      }


      exportToExcel(finalResult)
    } catch (err) {
      setLoading(false);
    }
  }

  const exportToExcel = (data) => {
    const name = "Thống kê theo nhà điều hành"
    const formattedData = data.map(item => ({
      'Nhà điều hành': item.bookmarkerName,
      'Thời gian': item.time,
      'Tổng user': item.totalUsers,
      'User mới': item.newUsers,
      'Đơn cược': item.count,
      'Tiền cược': item.totalBet,
      'Tiền khách thắng': item.paymentWin,
      'Lợi nhuận': item.profit,
      '% thắng': item.percentProfit,
    }));
    
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const columnFormats = [
      { header: 'Nhà điều hành', key: 'Nhà điều hành' },
      { header: 'Thời gian', key: 'Thời gian' },
      { header: 'Tổng user', key: 'Tổng user', type: 'n' },
      { header: 'User mới', key: 'User mới', type: 'n' },
      { header: 'Đơn cược', key: 'Đơn cược', type: 'n' },
      { header: 'Tiền cược', key: 'Tiền cược', type: 'n' },
      { header: 'Tiền khách thắng', key: 'Tiền khách thắng', type: 'n' },
      { header: 'Lợi nhuận', key: 'Lợi nhuận', type: 'n' },
      { header: '% thắng', key: '% thắng', type: 'n' },
    ];

    formattedData.forEach((row, rowIndex) => {
      columnFormats.forEach((col, colIndex) => {
        const cellRef = XLSX.utils.encode_cell({ c: colIndex, r: rowIndex + 1 });
        if (col.type === 'n') {
          ws[cellRef].t = 'n';
        }
      });
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${name}.xlsx`);
  };

  const disabledDateFrom = (current) => {
    const isAfterEndOfDay = (current) => current && current > dayjs().endOf("day");
    const isBeforeStartOfDateTo = (current, months) => current < dayjs(dateTo).subtract(months, "months").startOf("day");
    const isAfterEndOfDateTo = (current) => current > dayjs(dateTo).endOf("day");
    if (!current) return false;

    const monthsToSubtract = searchBy === "day" ? 3 : 2;

    if (!dateTo) {
      return isAfterEndOfDay(current);
    }

    return isAfterEndOfDay(current) || isBeforeStartOfDateTo(current, monthsToSubtract) || isAfterEndOfDateTo(current);
  };

  const disabledDateTo = (current) => {
    const isAfterEndOfDay = (current) => current && current > dayjs().endOf("day");
    const isBeforeStartOfDateFrom = (current) => current < dayjs(dateFrom).startOf("day");
    const isAfterEndOfDateFrom = (current, months) => current > dayjs(dateFrom).add(months, "months").endOf("day");
    if (!current) return false;

    const monthsToAdd = searchBy === "day" ? 3 : 2;

    if (!dateFrom) {
      return isAfterEndOfDay(current);
    }

    return isAfterEndOfDay(current) || isBeforeStartOfDateFrom(current) || isAfterEndOfDateFrom(current, monthsToAdd);
  };

  const disabledMonthFrom = (current) => {
    const today = dayjs().endOf('month');

    if (!monthTo) {
      return current && current > today;
    }

    const maxDate = dayjs(monthTo, 'MM-YYYY').endOf('month');
    const minDate = maxDate.subtract(2, 'month').startOf('month');

    return current && (current > maxDate || current < minDate);
  };

  const disabledMonthTo = (current) => {
    const today = dayjs().endOf('month');

    if (!monthFrom) {
      const today = dayjs().endOf('month');
      return current && current > today;
    }
    const minDate = dayjs(monthFrom, 'MM-YYYY');
    const maxDate = minDate.add(2, 'month').endOf('month');

    return current && (current < minDate || current > maxDate || current > today);
  };

  const renderSearch = () => {
    return (
      <>
        <div className="col-12">
          <Form form={form} layout="inline" autoComplete="off" onFinish={() => onSearch()}>
            <>
              <div className="col-12" style={{ marginBottom: 15, display: "flex" }}>
                {hasRight([RIGHTS.Super]) ? (
                  <div className="col-4">
                    <Form.Item name="bookmarker" label={`${TEXT_DEF.text0011}`} rules={[{ required: true, message: "Vui lòng nhập thông tin!" }]}>
                      <Select showSearch placeholder="Chọn nhà cái" optionFilterProp="children" onChange={(val) => setBookmarkerSelected(val)} filterOption={filterOptionBm} options={bookmarkers} allowClear />
                    </Form.Item>
                  </div>
                ) : null}
                <div className="col-4">
                  <Form.Item name="gameCategory" label={`Thể loại game`} rules={[{ required: true, message: "Vui lòng nhập thông tin!" }]}>
                    <Select showSearch optionFilterProp="children" onChange={(val) => setGameCategory(val)} filterOption={filterOptionBm} options={opGameCategory} allowClear />
                  </Form.Item>
                </div>
              </div>
              {gameCategory ? (
                <div className="col-12" style={{ marginBottom: 15, display: "flex" }}>
                  <div className="col-8 flex" style={{ alignItems: "center" }}>
                    <div className="col-1">Game: </div>
                    <Form.Item name="gameType" className="col-11">
                      <Select mode="multiple" showSearch optionFilterProp="children" onChange={(val) => setGame(val)} filterOption={filterOptionBm} options={gameTypes} allowClear />
                    </Form.Item>
                  </div>
                </div>
              ) : null}
              <div className="col-12" style={{ marginBottom: 15, display: "flex" }}>
                <div className="col-4">
                  <Form.Item name="searchBy" label={TEXT_DEF.text0096} rules={[{ required: true, message: "Vui lòng nhập thông tin!" }]}>
                    <Select onChange={(val) => setSearchBy(val)} allowClear>
                      <Select.Option value="month">{TEXT_DEF.text0090}</Select.Option>
                      <Select.Option value="day">{TEXT_DEF.text0089}</Select.Option>
                      {/* <Select.Option value="hour">Theo giờ</Select.Option> */}
                    </Select>
                  </Form.Item>
                </div>
                {searchBy === "day" || searchBy === "month" ? (
                  <>
                    <div className="col-4">
                      <Form.Item
                        name="dateFrom"
                        label={TEXT_DEF.text0024}
                        rules={[
                          { required: true, message: "Vui lòng nhập thông tin!" },
                          ...(searchBy === "day"
                            ? [
                                {
                                  validator: () => {
                                    return validateDateRange(dateFrom, dateTo);
                                  },
                                },
                              ]
                            : []),
                        ]}
                      >
                        {searchBy !== 'day' ? (
                          <>
                            {/* <DatePicker
                              picker={searchBy}
                              placeholder=""
                              onChange={(e) => onChangeDate(e, 'from')}
                              format={'MM-YYYY'}
                              style={{ width: '100%' }}
                              disabledTime={(d) => !d || d.isSameOrAfter(dateTo ? moment(dateTo).add(1, 'd') : moment())}
                              disabledDate={disabledDateFrom}
                            /> */}
                            <MonthPicker picker={searchBy} placeholder="" format={'MM-YYYY'} style={{ width: '100%' }} value={monthFrom ? dayjs(monthFrom, 'MM-YYYY') : null} onChange={(e) => onChangeDate(e, 'from')} disabledDate={disabledMonthFrom} />
                          </>
                        ) : (
                          <DatePicker
                            placeholder=""
                            onChange={(e) => onChangeDate(e, 'from')}
                            format={'DD-MM-YYYY HH:mm:ss'}
                            showTime={{ format: 'HH:mm:ss' }}
                            style={{ width: '100%' }}
                            disabledDate={disabledDateFrom}
                            disabledTime={disabledTimeFrom(dateTo)}
                            // inputReadOnly={true}
                          />
                        )}
                      </Form.Item>
                    </div>
                    <div className="col-4">
                      <Form.Item name="dateTo" label={TEXT_DEF.text0025} rules={[{ required: true, message: 'Vui lòng nhập thông tin!' }]}>
                        {searchBy !== 'day' ? (
                          // <DatePicker
                          //   picker={searchBy}
                          //   placeholder=""
                          //   onChange={(e) => onChangeDate(e, 'to')}
                          //   format={'MM-YYYY'}
                          //   style={{ width: '100%' }}
                          //   disabledTime={(d) => !d || d.isSameOrBefore(dateFrom ? dateFrom : null) || d.isSameOrAfter(moment())}
                          //   disabledDate={disabledDateTo}
                          // />
                          <MonthPicker picker={searchBy} placeholder="" format={'MM-YYYY'} style={{ width: '100%' }} value={monthTo ? dayjs(monthTo, 'MM-YYYY') : null} onChange={(e) => onChangeDate(e, 'to')} disabledDate={disabledMonthTo} />
                        ) : (
                          <DatePicker
                            placeholder=""
                            onChange={(e) => onChangeDate(e, "to")}
                            format={"DD-MM-YYYY HH:mm:ss"}
                            showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                            style={{ width: "100%" }}
                            disabledDate={disabledDateTo}
                            disabledTime={disabledTimeTo(dateFrom)}
                            // inputReadOnly={true}
                          />
                        )}
                      </Form.Item>
                    </div>
                  </>
                ) : null}
              </div>
            </>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0026}
              </Button>
              {hasRight([RIGHTS.ExportExcelReportOrdersByBookmarker]) ? (
                <Button onClick={onExportExl} style={{ marginLeft: 20 }} htmlType="submit" type="primary">
                  {TEXT_DEF.text0027}
                </Button>
              ) : null}
            </div>
          </Form>
        </div>
      </>
    );
  };

  const onChangePage = (page, size) => {
    setPage(page);
    setPageSize(size)
  };


  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const renderTableData = () => {
    return (
      <Spin spinning={loading} delay={500} size="large">
        <div
          style={{
            display: "flex",
            marginTop: 5,
            marginRight: 20,
          }}
        >
          <div className="text-lg font-bold mr-5">
            <label>Tổng users: </label> <span>{dollarUS.format(finalInfo.allUser)}</span>
          </div>
          <div className="text-lg font-bold mr-5">
            <label>User mới: </label> <span>{dollarUS.format(finalInfo.allNewUser)}</span>
          </div>
          <div className="text-lg font-bold mr-5">
            <label>Tổng đơn cược: </label> <span>{dollarUS.format(finalInfo.allOrders)}</span>
          </div>
          <div className="text-lg font-bold mr-5">
            <label>Tổng tiền cược: </label> <span>{dollarUS.format(finalInfo.allBet)}</span>
          </div>
          <div className="text-lg font-bold mr-5">
            <label>Tổng tiền khách thắng: </label> <span>{dollarUS.format(finalInfo.allAmountUserWin)}</span>
          </div>
          <div className="text-lg font-bold mr-5">
            <label>Tổng lợi nhuận: </label> <span>{dollarUS.format(finalInfo.allProfit)}</span>
          </div>
          <div className="text-lg font-bold mr-5">
            <label>Phần trăm lợi nhuận: </label> <span>{finalInfo.allPercentProfit}</span>
          </div>
        </div>
        <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
          <Table
            key="userList"
            locale={EMPTY_DATA}
            columns={colums}
            dataSource={ordersInfo}
            pagination={false}
            bordered
            style={{ width: "100%" }}
            scroll={{
              x: 1200,
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 15,
            justifyContent: "flex-end",
          }}
        >
          <div>
            <Pagination 
              current={page} 
              total={totalRecord} 
              onChange={onChangePage} 
              showTotal={showTotal} 
              showSizeChanger
              onShowSizeChange={onChangePage}
              pageSizeOptions={['10', '50', '100', '200', '500', '1000']}
            />
          </div>
        </div>
      </Spin>
    );
  }

  return (
    <div className="col-12">
      {renderSearch()}
      {renderTableData()}
      {isShowModalChart && (
        <ModalChart
          isShow={isShowModalChart}
          dataChart={dataChartModal}
          title={titleChartModal}
          close={() => setIsShowModalChart(false)}
        />
      )}
    </div>
  );
};

export default ReportByBookmaker;
