import React, { useEffect, useState } from 'react';
import { TEXT_DEF } from '../../../constant/text';
import { Button } from 'antd';
import ModalCommon from '../../../components/Modal';
import API, { ADMIN_XOCDIA } from '../../../network/api';
import { RIGHTS } from '../../../constant/rights';
import { hasRight } from '../../../components/until/right';
import CustomInputNumber from '../../../components/common/CustomInputNumber';
import RenderSwitch from '../../../components/common/Switch';

const XocDiaSetting = () => {
  const [isMaintenance, setIsMaintenance] = useState();
  const [dataUp, setDataUp] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [valueToUpdate, setValueToUpdate] = useState({});
  const labels = {
    chan: 'Chẵn',
    le: 'Lẻ',
    tai: 'Tài',
    xiu: 'Xỉu',
    "4trang": '4 trắng',
    "4do": '4 đỏ',
    "3do-1trang": '3 đỏ 1 trắng',
    "1do-3trang": '1 đỏ 3 trắng',
  };

  useEffect(() => {
    getDataXocDia();
  }, []);

  const getDataXocDia = async () => {
    const response = await API.get(`${ADMIN_XOCDIA}/getConfig`);
    const resultGame = response.data?.data?.result;
    setData(resultGame);
  };


  const showConfirm = (key) => {
    let params;
    const id = data?.find((x) => x.key === key)?.id;
    switch (key) {
      case 'isMaintenance':
        params = {
          key: 'isMaintenance',
          value: isMaintenance.toString(),
        };
        break;
      case 'chan':
        params = {
          key: 'chan',
          value: valueToUpdate[key].toString(),
        };
        break;
      case 'le':
        params = {
          key: 'le',
          value: valueToUpdate[key].toString(),
        };
        break;
      case 'tai':
        params = {
          key: 'tai',
          value: valueToUpdate[key].toString(),
        };
        break;
      case 'xiu':
        params = {
          key: 'xiu',
          value: valueToUpdate[key].toString(),
        };
        break;
        case '4trang':
          params = {
            key: '4trang',
            value: valueToUpdate[key].toString(),
          };
          break;
        case '4do':
          params = {
            key: '4do',
            value: valueToUpdate[key].toString(),
          };
          break;
        case '3do-1trang':
          params = {
            key: '3do-1trang',
            value: valueToUpdate[key].toString(),
          };
          break;
        case '1do-3trang':
          params = {
            key: '1do-3trang',
            value: valueToUpdate[key].toString(),
          };
          break;
      default:
        break;
    }
    const dataReq = {
      id: id,
      params: params,
    };
    setDataUp(dataReq);
    setIsShowModalConfirm(true);
  };

  const handleChangeSwitch = (e) => {
    setIsMaintenance(e);
  };

  const onRequest = async () => {
    if (!dataUp) return;
    await API.patch(`${ADMIN_XOCDIA}/updateConfig/${dataUp?.id}`, dataUp?.params)
      .then((response) => {
        const result = response.data?.data;
        if (result?.code === 20004) {
          setLoading(true);
          setIsShowModalConfirm(false);
          setIsShowModalSuccess(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetKey = (value) => {
    showConfirm(value);
  };

  const handleInputChange = (key, newValue) => {
    setValueToUpdate((prev) => ({ ...prev, [key]: Number(newValue) }));
  };

  const renderInputFields = () => {
    if (!data || data.length === 0) return;

    const keysToFilter = ['chan', 'le', 'tai', 'xiu', '4do', '4trang', '3do-1trang', '1do-3trang'];
    const filteredData = data.filter((item) => keysToFilter.includes(item.key));

    return (
      <>
        {filteredData.length > 0 &&
          filteredData?.map((item, index) => (
            <>
              <div className="col-12 flex" key={index}>
                <div className="col-3 text-left">{labels[item?.key] || item.key}</div>
                <div className="col-6">
                  <CustomInputNumber min={0} max={9999} step={0.01} value={item?.value} onChange={(e) => handleInputChange(item.key, e)} />
                </div>
                {hasRight([RIGHTS.EditSettingOriginals]) ? (
                  <div className="col-3 text-left">
                    <Button
                      type="primary"
                      onClick={() => {
                        handleGetKey(item?.key);
                      }}
                    >
                      {TEXT_DEF.text0016}
                    </Button>
                  </div>
                ) : null}
              </div>
              <div style={{ margin: '10px 0px' }} />
            </>
          ))}
      </>
    );
  };

  return (
    <>
      {data ? (
        <div className="col-12 flex-col" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
          <RenderSwitch data={data} labels={labels} handleChangeSwitch={handleChangeSwitch} hasRight={hasRight} handleGetKey={handleGetKey} />
          <div style={{ margin: '10px 0px' }} />
          {renderInputFields()}
          <div style={{ margin: '10px 0px' }} />
        </div>
      ) : null}
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text0071}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0072}
          actionNext={onRequest}
          close={() => {
            setIsShowModalConfirm(false);
            setDataUp(undefined);
          }}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text0073}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </>
  );
};

export default XocDiaSetting;
